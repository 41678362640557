"use client";
import React, { createContext, useContext, useState, useEffect, useRef, FC, ReactNode } from 'react';
import { myChannelService } from '../service/myChannelService';
import utilsService from '../helpers/utilsService';
import { logger } from '../lib/logger';

interface PointsContextType {
    points: number | null;
    refreshPoints: () => Promise<void>;
    getPoints: () => Promise<void>;
}

const PointsContext = createContext<PointsContextType | undefined>(undefined);

interface IPoint {
    children: ReactNode;
}

export const PointsProvider: FC<IPoint> = ({ children }) => {
    const [points, setPoints] = useState<number | null>(null);
    const fetchControllerBanner = useRef<AbortController | null>(null);

    const getPoints = async () => {
        try {
            if (fetchControllerBanner.current) {
                fetchControllerBanner.current.abort();
            }
            fetchControllerBanner.current = new AbortController();
            const signal = fetchControllerBanner.current.signal;
            const { data } = await myChannelService.getPoints(signal);
            setPoints(data?.point ?? 0);
        } catch (error) {
            const errorMessage = utilsService.getErrorMessage(error);
            if (errorMessage !== 'canceled') {
                logger.error(errorMessage);
            }
        }
    };

    const refreshPoints = async () => {
        await getPoints();
    };
    useEffect(() => {
        getPoints(); // Initial fetch on mount
    }, []);

    return (
        <PointsContext.Provider value={{ points, refreshPoints, getPoints }}>
            {children}
        </PointsContext.Provider>
    );
};

export const usePoints = (): PointsContextType => {
    const context = useContext(PointsContext);
    if (!context) {
        throw new Error('usePoints must be used within a PointsProvider');
    }
    return context;
};
