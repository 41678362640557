'use client';
import { createContext, useContext, useState, ReactNode } from 'react';

export enum TypeDialog {
    alert = 'alert',
    error = 'error',
    drawer = 'drawer', //handle later when have free time
    complex = 'complex',
}

interface DialogState {
    isOpen: boolean;
    type?: TypeDialog;
    title: string;
    icon?: React.ReactNode;
    message: string;
    showCancel?: boolean;
    showBaseCardNotice?: boolean;
    baseCardContent?: ReactNode;
    showAccept?: boolean;
    onCancel?: () => void;
    onAccept?: () => void;
    cancelText?: string;
    acceptText?: string;
}

interface GlobalDialogV2ContextType {
    dialogState: DialogState;
    showDialog: (config: Omit<DialogState, 'isOpen'>) => void;
    hideDialog: () => void;
}

const GlobalDialogContext = createContext<GlobalDialogV2ContextType | undefined>(undefined);

export const GlobalDialogV2Provider = ({ children }: { children: ReactNode }) => {
    const [dialogState, setDialogState] = useState<DialogState>({
        isOpen: false,
        type: TypeDialog.alert,
        title: '',
        message: '',
        showCancel: true,
        showAccept: true,
    });

    const showDialog = (config: Omit<DialogState, 'isOpen'>) => {
        setDialogState({
            ...config,
            isOpen: true,
        });
    };

    const hideDialog = () => {
        setDialogState((prev) => ({
            ...prev,
            isOpen: false,
        }));
    };

    return <GlobalDialogContext.Provider value={{ dialogState, showDialog, hideDialog }}>{children}</GlobalDialogContext.Provider>;
};

export function useGlobalDialogV2(): GlobalDialogV2ContextType {
    const context = useContext(GlobalDialogContext);
    if (!context) {
        throw new Error('useGlobalDialog must be used within a V2GlobalDialogProvider');
    }
    return context;
}
