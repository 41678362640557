import fetchWrapper from '../fetchWrapper';

const BASE_URL = process.env.BASE_URL;

async function authenticationLogin(accessToken: string, type: string): Promise<any> {
    try {
        const response = await fetchWrapper.post(`${BASE_URL}/auth/login`, {
            input_token: accessToken,
            type,
        });
        return response;
    } catch (error: any) {
        throw new Error(`${error.message ?? 'Failed to authenticate with Google'}`);
    }
}

async function refreshToken(): Promise<any> {
    try {
        const response = await fetchWrapper.post(`${BASE_URL}/auth/refresh-token`);
        return response;
    } catch (error: any) {
        throw new Error(`${error.response?.data || error.message}`);
    }
}

async function logout(): Promise<void> {
    try {
        await fetchWrapper.post(`${BASE_URL}/auth/logout`);
    } catch (error: any) {
        throw error;
    }
}

export const authService = {
    authenticationLogin,
    refreshToken,
    // authenticationLoginGoogle,
    // authenticationLoginApple,
    logout,
};
