'use client';

import * as React from 'react';
import { logger } from '@/app/lib/logger/default-logger';
import moment from 'moment';
import { authService } from '../service/authService';
import { clearUserCookies, deleteExpiredToken, getAccessToken, getExpiredToken, setExpiredToken, updateCookie } from '../helpers/cookiesMethod';
import { useUser } from '../hooks/useUser';
import { useAuthenticationPopUp } from './authentication-popup-context';
import { getCookie } from 'cookies-next';
import { useRouter } from 'next/navigation';

export interface TokenGuardProps {
    children: React.ReactNode;
}
const COOKIE_NAME = 'auth_ignore';

export function TokenGuard({ children }: TokenGuardProps): React.JSX.Element | null {
    const { isDialogOpen, confirmPopUp } = useAuthenticationPopUp();
    const { user: userLocal } = useUser();
    const [initialLoad, setInitialLoad] = React.useState<boolean>(true);
    const [intervalId, setIntervalId] = React.useState<NodeJS.Timeout | null>(null);
    const router = useRouter();
    const refreshToken = async () => {
        try {
            if (!userLocal?.id) {
                clearUserCookies();
                deleteExpiredToken();
                return;
            }
            const response = await authService.refreshToken();
            if (response?.data?.is_suspend || response?.data?.is_deleted) {
                confirmPopUp.open({
                    title: response?.msg,
                    message: '',
                    acceptActions: () => {
                        router.push('/login');
                    },
                    cancelActions: () => null,
                    showCloseButton: false,
                    showAcceptButton: true,
                });
                clearUserCookies();
                deleteExpiredToken();
                return;
            }
            const { access_token, user, expired_at } = response.data?.data;
            const userObject = {
                id: userLocal?.id ?? user?.id,
                nickname: user?.nickname || '',
                avatar: user.avatar,
                email: user?.email || undefined,
                contacts: user?.contacts ?? [],
                isStaff: user?.is_staff ?? null,
                isInfluencer: user?.is_influencer ?? null,
                isExpert: user?.is_expert ?? null,
                avatarUrl: user?.avatar ?? null,
                isGoogleLoggedIn: user?.is_google_logged_in,
                isAppleLoggedIn: user?.is_apple_logged_in,
                phoneNumber: user?.phone_number ?? null,
                updatedAt: user?.updated_at ? moment(user.updated_at) : moment(),
                createdAt: user?.created_at ? moment(user.created_at) : moment(),
                profile: user?.profile,
            };
            updateCookie({ accessToken: access_token, user: userObject });
            const expirationTime = moment().add(expired_at, 'seconds').toISOString();
            setExpiredToken(expirationTime);
        } catch (error: any) {
            clearUserCookies();
            deleteExpiredToken();
            if (isDialogOpen) return;
        }
    };

    const scheduleTokenRefresh = () => {
        const expiredToken = getExpiredToken();
        if (expiredToken && userLocal?.id) {
            const expirationTime = moment(JSON.parse(expiredToken));
            const refreshTime = expirationTime.subtract(170, 'seconds');
            const delay = refreshTime.diff(moment());

            if (delay > 0) {
                const newIntervalId = setTimeout(async () => {
                    await refreshToken();
                    scheduleTokenRefresh();
                }, delay);

                if (intervalId) {
                    clearTimeout(intervalId);
                }

                setIntervalId(newIntervalId);
            } else {
                refreshToken().then(() => {
                    scheduleTokenRefresh();
                });
            }
        }
    };

    const checkTokenExpiration = async () => {
        const expiredToken = getExpiredToken();
        if (expiredToken) {
            const expirationTime = moment(JSON.parse(expiredToken));
            const timeDiff = expirationTime.diff(moment(), 'seconds');
            if (timeDiff < 100) {
                await refreshToken();
            } else {
                return; //
            }
        }
    };

    React.useEffect(() => {
        if (initialLoad) {
            checkTokenExpiration().catch((e) => {
                logger.error('Failed to check token expiration:', e);
            });
            setInitialLoad(false);
        }
    }, []);

    React.useEffect(() => {
        if (!initialLoad) {
            if (userLocal?.id) {
                scheduleTokenRefresh();
            } else if (!userLocal?.id && intervalId) {
                clearTimeout(intervalId);
            }
            return () => {
                if (intervalId) {
                    clearTimeout(intervalId);
                }
            };
        }
    }, [initialLoad, userLocal?.id]);

    return <React.Fragment>{children}</React.Fragment>;
}
