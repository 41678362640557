'use client';

import { useGlobalDialog } from '@/app/contexts/global-dialog';
import { Box, CircularProgress, Dialog } from '@mui/material';

const LoadingGlobalJoy = () => {
    const { isDialogLoadingOpen } = useGlobalDialog();
    if (!isDialogLoadingOpen) return null; //avoid render not necessary
    return (
        <Dialog
            open={isDialogLoadingOpen}
            sx={{
                zIndex: 999999,
                '& .MuiPaper-root': {
                    borderRadius: '30px',
                    width: '120px',
                    height: '120px',
                    zIndex: 999999
                },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '120px',
                    height: '120px',
                }}
            >
                <CircularProgress thickness={4.8} size="60px"/>
            </Box>
        </Dialog>
    );
};

export default LoadingGlobalJoy;
